<template>
	<v-sheet class="equipment" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex>
							<p class="my-auto font-level-3-bold">
								<span class="fw-500">
									<b class="text-truncate d-inline-block" style="max-width: 42%; vertical-align: middle">
										<ShowValue :object="equipment" object-key="name" label="equipment name"></ShowValue>
									</b>
									<v-chip color="cyan" class="ml-2 mr-2" label text-color="white"
										>{{ equipment.barcode }}
									</v-chip>
								</span>
							</p>
						</v-flex>
						<v-flex :class="`text-right`">
							<v-btn
								v-if="getPermission('equipment::edit')"
								v-on:click="doAction('edit')"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
							>
								<v-icon small left>mdi-pencil</v-icon> Edit
							</v-btn>
							<v-btn
								v-on:click="backToListing('equipment')"
								color="grey lighten-3"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
							>
						</v-flex>
					</v-layout>
					<div class="d-flex">
						<div class="w-10 pr-3" style="max-width: 200px">
							<ImageTemplate circle :src="equipment.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3 mt-2">
							<div class="d-flex flex-wrap">
								<div class="width-33 px-2">
									<div class="gradient-color-grey h-100">
										<div class="fw-600 grey lighten-2 py-1 px-2" style="background-color: #e3dada">
											Details
										</div>
										<table style="width: 100%">
											<tr>
												<td class="px-2 py-1">Name</td>
												<td class="px-2 py-1 fw-500">
													<ShowValue :object="equipment" object-key="name" label="Name"></ShowValue>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Barcode</td>
												<td class="px-2 py-1 fw-500 no-wrap">
													<ShowValue :object="equipment" object-key="barcode" label="Barcode"></ShowValue>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Brand</td>
												<td class="px-2 py-1 fw-500 no-wrap">
													<ShowValue :object="equipment" object-key="brand_name" label="brand"></ShowValue>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Category</td>
												<td class="px-2 py-1 fw-500">
													<ShowValue :object="equipment" object-key="group_name" label="category"></ShowValue>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Manufacturer</td>
												<td class="px-2 py-1 fw-500 no-wrap">
													<ShowValue
														:object="equipment"
														object-key="manufacturer"
														label="manufacturer"
													></ShowValue>
												</td>
											</tr>
										</table>
									</div>
								</div>
								<div class="width-33 px-2">
									<div class="gradient-color-grey h-100">
										<div class="grey fw-600 lighten-2 py-1 px-2">Purchase Information</div>
										<table>
											<tr>
												<td class="px-2 py-1">Supplier</td>
												<td class="px-2 py-1 fw-500 no-wrap">
													<ShowValue
														:object="equipment"
														object-key="supplier_display_name"
														label="supplier"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Initial stock</td>
												<td class="px-2 py-1 fw-500">
													<v-chip
														color="orange lighten-4 orange--text text--darken-4"
														v-if="equipment.initial_stock"
													>
														<span style="font-size: 18px">
															{{ equipment.initial_stock }}
														</span>
													</v-chip>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Cost Price</td>
												<td class="px-2 py-1 fw-600" style="font-size: 18px">
													<ShowValue
														:object="equipment"
														object-key="cost_price_formatted"
														label="cost price"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Purchased On</td>
												<td class="px-2 py-1 fw-500 no-wrap">
													<ShowValue
														:object="equipment"
														object-key="purchased_on_formatted"
														label="purchase on"
													></ShowValue>
												</td>
											</tr>
										</table>
									</div>
								</div>
								<div class="width-33 px-2">
									<div class="gradient-color-grey h-100">
										<div class="grey fw-600 lighten-2 py-1 px-2">Create Information</div>
										<table>
											<tr>
												<td class="px-2 py-1">Created date</td>
												<td class="px-2 py-1">
													<span class="fw-500" v-if="equipment.added_at">
														{{ formatDateTime(equipment.added_at) }}
													</span>
													<em v-else class="text-muted">no created date</em>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Created By</td>
												<td class="px-2 py-1 fw-500">
													<ShowValue :object="equipment" object-key="added_by" label="created by"></ShowValue>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Updated date</td>
												<td class="px-2 py-1">
													<span class="fw-500" v-if="equipment.updated_at">
														{{ formatDateTime(equipment.updated_at) }}
													</span>
													<em v-else class="text-muted">no updated date</em>
												</td>
											</tr>
											<tr>
												<td class="px-2 py-1">Updated By</td>
												<td class="px-2 py-1 fw-500">
													<ShowValue :object="equipment" object-key="updated_by" label="updated by"></ShowValue>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="equipmentTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail"> <v-icon small left>mdi-information-outline</v-icon> Details </v-tab>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files </v-tab>
					<v-tab href="#comment" v-if="false">
						<v-icon small left>mdi-comment-multiple</v-icon> Comments
					</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="equipmentTab">
					<v-tab-item value="detail">
						<div class="mt-2">
							<div class="overflow-y w-100 d-flex" style="max-height: calc(100vh - 250px)">
								<div class="width-100 gradient-color-10">
									<v-layout class="px-2 py-1 blue lighten-4">
										<v-flex class="font-level-3-bold my-auto">
											<v-icon small>mdi-information-outline</v-icon>

											Equipment Details
										</v-flex>
									</v-layout>
									<table width="100%" class="detail-table scattered-table ml-2">
										<tr>
											<th width="100">Tags</th>
											<td width="20">:</td>
											<td>
												<template v-if="equipment && equipment.tags.length > 0">
													<v-chip
														v-for="(tagRow, tagIndex) in equipment.tags"
														:key="`tag-${tagIndex}`"
														color="#58b5bd"
														text-color="white"
														class="ml-1"
													>
														{{ tagRow }}
													</v-chip>
												</template>
												<template v-else>
													<em class="text-muted">no tags</em>
												</template>
											</td>
										</tr>
										<tr>
											<th valign="top">Remark</th>
											<td valign="top">:</td>
											<td valign="top">
												<ShowTextAreaValue :object="equipment" object-key="remark" label="remark">
												</ShowTextAreaValue>
											</td>
										</tr>
										<tr>
											<th>Description</th>
											<td>:</td>
											<td>
												<ShowTextAreaValue :object="equipment" object-key="description" label="description">
												</ShowTextAreaValue>
											</td>
										</tr>
									</table>
								</div>
								<v-spacer></v-spacer>
								<div class="width-100 gradient-color-9" v-if="equipment.has_warranty">
									<v-layout class="px-2 py-1 orange lighten-4">
										<v-flex class="font-level-3-bold my-auto">
											<v-icon small>mdi-shield-check-outline</v-icon>

											Warranty Details
											<v-chip v-if="false && equipment" color="red" text-color="white" class="ml-1">
												Expired
											</v-chip>
										</v-flex>
									</v-layout>
									<div class="expired_details">
										<table width="100%" class="detail-table scattered-table ml-2">
											<tr>
												<th width="250">Warranty Period</th>
												<td width="20">:</td>
												<td>
													<ShowValue
														:object="equipment"
														object-key="warranty_period"
														label="Warranty Period"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th width="250">Warranty Start Date</th>
												<td width="20">:</td>
												<td>
													<ShowValue
														:object="equipment"
														object-key="warranty_start_date_formatted"
														label="warranty Start Date"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th>Warranty End Date</th>
												<td>:</td>
												<td>
													<ShowValue
														:object="equipment"
														object-key="warranty_end_date_formatted"
														label="warranty End Date"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th>Warranty Reminder Start Date</th>
												<td>:</td>
												<td>
													<ShowValue
														:object="equipment"
														object-key="warranty_reminder_date_formatted"
														label="warranty Reminder Start Date"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th>Warranty Reminder End Date</th>
												<td>:</td>
												<td>
													<ShowValue
														:object="equipment"
														object-key="warranty_reminder_endDate_formatted"
														label="Warranty Reminder End Date"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th>Warranty Reminder Frequency</th>
												<td>:</td>
												<td>
													<ShowValue
														:object="equipment"
														object-key="warranty_reminder_type_text"
														label="Warranty Reminder Frequency"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th>Warranty Cost</th>
												<td>:</td>
												<td>
													<ShowValue
														:object="equipment"
														object-key="warranty_cost_formatted"
														label="Warranty Cost"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th>Warranty Remark</th>
												<td>:</td>
												<td>
													<ShowTextAreaValue
														:object="equipment"
														object-key="warranty_remark"
														label="Warranty Remark"
													>
													</ShowTextAreaValue>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="equipmentTab == 'file'"
							class="mx-4"
							type-text="Equipment Files"
							type="equipment"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item v-if="false" value="comment">
						<Comments
							v-if="equipmentTab == 'comment'"
							class="mx-4"
							type-text="Equipment"
							type="equipment"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="equipmentTab == 'event'"
							class="mx-4"
							type-text="Equipment"
							type="equipment"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Equipment"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!equipment.retired
						? 'To preserve associated records, you could retire the Equipment instead.'
						: null
				"
			>
				<template v-if="!equipment.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import TooltipQuestion from "@/view/components/TooltipQuestion";
import ImageTemplate from "@/view/components/Image";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ShowValue from "@/view/components/ShowValue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";

import DeleteTemplate from "@/view/components/DeleteTemplate";
import EventListing from "@/view/components/EventListing";

import { EventBus } from "@/core/event-bus/event.bus";

import { GetEquipment } from "@/core/lib/equipment.lib";

export default {
	name: "equipment-detail",
	title: "Detail Equipment",
	data() {
		return {
			extendCheckoutDialog: false,
			checkinDialog: false,
			serviceDialog: false,
			reservationDialog: false,
			serviceScheduleDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			checkoutDialog: false,
			retireDialog: false,
			deleteDialog: false,
			deleteURL: null,
			deleteText: null,

			equipmentTab: null,
			uuid: null,
			Barcode: null,
			contentLoaded: false,
			pageLoading: true,
			equipment: {},
			schedule: {},
			serviceSchedule: [],
			deviceWidth: document.documentElement.clientWidth,
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	methods: {
		initService(param) {
			this.schedule = param;
			this.serviceScheduleDialog = true;
		},
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "equipment-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "equipment-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "retire":
					this.retireDialog = true;
					break;
				case "print-label":
					break;
				case "checkout-history":
					break;
				case "verification-history":
					break;
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "reserve":
					this.reservationDialog = true;
					break;
				case "extend-checkout":
					this.extendCheckoutDialog = true;
					break;
				case "checkin":
					this.checkinDialog = true;
					break;
				case "start-service":
					this.serviceDialog = true;
					break;
				case "schedule-service":
					this.serviceScheduleDialog = true;
					break;
				case "complete-service":
					this.serviceCompleteDialog = true;
					break;
				case "extend-service":
					this.serviceExtendDialog = true;
					break;
			}
		},
		getServices() {
			EventBus.$emit("reload:equipment-service", true);
		},
		getEquipment() {
			GetEquipment(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.serviceSchedule = data.service_schedule;
					this.equipment = data;
					this.deleteText = `#${data.barcode} - ${data.name}`;
					this.deleteURL = `equipment/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Equipment", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		ShowValue,
		ShowTextAreaValue,

		Comments,
		DeleteTemplate,
		EventListing,
		// TooltipQuestion,
		ImageTemplate,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getEquipment();
		this.pageLoading = false;
		this.contentLoaded = true;

		EventBus.$on("reload:equipment", () => {
			this.getEquipment();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:equipment");
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
